// import "./sentry.js"
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigurationWrapper } from "./components/digitalfriend/ConfigurationWrapper";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="*" element={<ConfigurationWrapper />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
);
