import React, { useEffect, useRef, useState } from "react";
import { IMessage, useChatState } from "../../state/ChatState";
import { useScript } from "../../../hooks/useScript";
import { applyConfiguration } from "../../helpers/applyConfiguration";
import { MicrophoneActions } from "../MicrophoneActions";
import { ChooseAgent } from "./ChooseAgent";
import { LoadingSpinner } from "../LoadingSpinner";
import { Message } from "../Message";
import { formatTime } from "../../helpers/helper";

declare global {
	interface Window {
		symantoAgentTester: {
			startConversation: (
				agentId: string,
				apiKey: string,
				baseWebSocketUrl: string,
				isChat?: boolean,
			) => void;
			stopConversation: () => void;
			test: () => void;
			messageChannel: MessageChannel;
			pauseMic: () => void;
			unpauseMic: () => void;
			sendTextMessage: (message: string) => void;
		};
	}
}

export const DigitalFriendWS = () => {
	const chatState = useChatState(); // global state
	const loaded = useScript({ src: "/scripts/testAgentScript.js" });
	const messages = chatState.chatMessages;

	const [initApproved, setInitApproved] = useState(false);
	const [isInitializing, setInitializing] = useState(false);
	const [languageIndex, setLanguageIndex] = useState(0);
	const [callDuration, setCallDuration] = useState(0);
	const callDurationRef = useRef(callDuration);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	const isVoiceChat = chatState.baseConfig?.IS_VOICE_CHAT == "true";
	const inCallLabels = chatState.baseConfig?.IN_CALL_LABELS?.split("|");
	const endCallLabels = chatState.baseConfig?.END_CALL_LABELS?.split("|");
	const connectingMessages =
		chatState.baseConfig?.CONNECTING_MESSAGES?.split("|");
	const productChatLogoUrl =
		chatState.baseConfig?.PRODUCT_CHAT_LOGO_URL ||
		"https://symantopublic.blob.core.windows.net/logos/symanto.svg";

	useEffect(() => {
		if (callDuration) callDurationRef.current = callDuration;
	}, [callDuration]);

	applyConfiguration(
		String(chatState.baseConfig?.PRIMARY_COLOR),
		String(chatState.baseConfig?.SECONDARY_COLOR),
		String(chatState.baseConfig?.TITLE),
		chatState.baseConfig?.BACKGROUND_COLOR,
	);

	/**
	 * The `startCall` function initiates a conversation with a Symanto agent, processes incoming
	 * messages, updates the chat state, and sets the microphone as active.
	 * @param {string} agentId - The `agentId` parameter in the `startCall` function is a string that
	 * represents the identifier of the agent with whom the conversation will be started.
	 */
	const startCall = (agentId: string) => {
		window.symantoAgentTester.startConversation(
			agentId,
			chatState.baseConfig!.API_KEY,
			chatState.baseConfig!.BASE_API_URL,
		);

		window.symantoAgentTester.messageChannel.port2.onmessage = (event) => {
			const { Id, Role, IsComplete, Content } = event.data;
			const _msg: IMessage = {
				id: Id,
				conversationId: Id,
				messageAuthor: Role == "2" ? 0 : 1,
				text: Content,
				finalMessage: IsComplete,
				date: new Date().toISOString(),
			};
			chatState.updateMessage(_msg, true);
		};

		chatState.setIsMicActive(true);
		setInitializing(false);
		startDuration();
	};

	const stopCall = () => {
		window.location.reload();
	};

	/**
	 * The function `pauseMicrophone` pauses the microphone and updates the chat state to indicate that
	 * the microphone is inactive.
	 */
	const pauseMicrophone = () => {
		window.symantoAgentTester.pauseMic();
		chatState.setIsMicActive(false);
	};

	/**
	 * The function `unpauseMicrophone` unpauses the microphone and sets the chat state to indicate that
	 * the microphone is active.
	 */
	const unpauseMicrophone = () => {
		window.symantoAgentTester.unpauseMic();
		chatState.setIsMicActive(true);
	};

	/**
	 * The `initChat` function initializes a chat with an agent using the provided agentId and optional
	 * promptId.
	 * @param {number} langIndex - The `languageIndex` parameter is a required number that represents the selected language from the choose agent view
	 * @param {string} agentId - The `agentId` parameter is a required string that represents the
	 * identifier of the agent for the chat initialization.
	 * @param {string} [promptId] - The `promptId` parameter in the `initChat` function is an optional
	 * parameter of type string. It is used to specify a prompt ID that can be passed to the `startCall`
	 * function when initiating a chat. If a `promptId` is provided, the `startCall` function
	 */
	const initChat = (langIndex: number, agentId: string, promptId?: string) => {
		setInitApproved(true);
		setInitializing(true);
		setLanguageIndex(langIndex);

		if (promptId && promptId != "undefined") {
			startCall(promptId);
		} else {
			startCall(agentId);
		}
	};

	const startDuration = () => {
		intervalRef.current = setInterval(() => {
			setCallDuration((prev) => prev + 1);
		}, 1000);
	};

	return (
		<div className="min-h-[100vh] max-h-[100vh] flex overflow-y-auto">
			{loaded === false && (
				<div className="flex flex-1 items-center justify-center">
					<LoadingSpinner />
				</div>
			)}

			{loaded === true && (
				<div className="flex flex-1 flex-col items-center p-4 overflow-y-auto">
					{isVoiceChat && initApproved && (
						<div className="absolute bottom-0 left-0 mb-0 ml-3 z-10">
							<MicrophoneActions
								isActive={chatState.isMicActive}
								onPlay={() => unpauseMicrophone()}
								onStop={() => pauseMicrophone()}
							/>
						</div>
					)}

					{initApproved && (
						<div className="w-full max-w-[900px] flex flex-col flex-1 overflow-y-auto">
							{isInitializing && (
								<div className="flex w-full mt-1 md:mt-4 gap-3 items-center">
									{!!productChatLogoUrl && (
										<img
											src={productChatLogoUrl}
											className="w-full max-w-[50px] max-h-[50px] md:max-w-[60px] md:max-h-[60px] object-contain"
										/>
									)}
									<p className="text-sm md:text-base">
										{connectingMessages?.[languageIndex]}
									</p>
								</div>
							)}

							{!isInitializing && (
								<>
									<div className="flex items-center justify-center mb-4 md:mb-6">
										{isVoiceChat && (
											<p className="text-sm md:text-base">
												{inCallLabels?.[languageIndex]?.replace(
													"{{time}}",
													formatTime(callDuration),
												)}
												.
											</p>
										)}
										<p
											className="text-sm md:text-base underline ml-2 cursor-pointer"
											onClick={stopCall}
										>
											{endCallLabels?.[languageIndex]}
										</p>
									</div>
									<div
										id="chat"
										className="flex flex-1 flex-col gap-2 md:gap-6 overflow-y-auto"
									>
										{messages?.map((message, index) => {
											return (
												<Message
													key={index}
													message={message}
													isIframe={false}
												/>
											);
										})}
									</div>
								</>
							)}
						</div>
					)}

					{initApproved === false && <ChooseAgent selectedAgent={initChat} />}

					<div className="flex items-center justify-center mt-3 md:mt-6">
						<img
							src="/powered-by-symanto-logo.svg"
							className="max-h-[26px] md:max-h-[60px]"
							alt="Logo"
						/>
					</div>
				</div>
			)}
		</div>
	);
};
