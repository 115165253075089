import { hookstate, useHookstate } from "@hookstate/core";
import { setPreferredLanguage } from "../helpers/helper";
import { IConfiguration } from "../interfaces/types";

export interface IChatStateModel {
	isMicActive: boolean;
	language: string;
	modelName: string;
	chatMessages: IMessage[];
	baseConfig: IConfiguration | null;
}

export interface IMessage {
	id: string;
	conversationId: string;
	messageAuthor: IMessageAuthor;
	text: string;
	date: string;
	finalMessage?: boolean;
	time?: string;
}

export type IMessageAuthor = 0 | 1;

const initialState = {
	isMicActive: false,
	language: "de",
	chatMessages: [],
	modelName: "de-DE-SeraphinaMultilingualNeural",
	baseConfig: null,
};

const chatState = hookstate(<IChatStateModel>initialState);

const scrollLatestChat = () => {
	const d = document.querySelector("#chat");
	d?.scrollTo({ top: d.scrollHeight, behavior: "smooth" });
};

export function useChatState() {
	const state = useHookstate(chatState);

	return {
		get isMicActive() {
			return state.isMicActive.get();
		},
		get language() {
			return state.language.get();
		},
		get modelName() {
			return state.modelName.get();
		},
		get chatMessages() {
			return state.chatMessages.get();
		},
		get baseConfig() {
			return state.baseConfig.get();
		},

		setIsMicActive(t: boolean) {
			state.isMicActive.set(t);
		},
		setBaseConfig: (t: IConfiguration) => {
			state.baseConfig.set(t);
		},
		setLanguage(t: string) {
			setPreferredLanguage(t);
			if (state.language.get() != t) {
				state.language.set(t);
			}
		},
		setModelName(t: string) {
			if (state.modelName.get() != t) {
				state.modelName.set(t);
			}
		},
		updateMessage(t: IMessage, wsSent = false) {
			const exists = state.chatMessages.get().find((e) => e.id === t.id);
			if (!exists) {
				state.chatMessages.set((x) => {
					if (!t.text) t.text = "";
					t.text = t.text.replaceAll(/<[^>]+>/g, "");
					x.push(t);
					return x;
				});
			} else {
				state.chatMessages.set((m) => {
					return m.map((e) => {
						if (e.id === t.id) {
							if (wsSent) {
								e.text += t.text;
							} else {
								e.text = t.text;
							}
							e.text = e.text.replaceAll(/<[^>]+>/g, "");
							e.finalMessage = t.finalMessage;
						}

						if (
							e.finalMessage == false &&
							t.finalMessage == false &&
							e.id !== t.id
						) {
							e.finalMessage = true;
						}
						return e;
					});
				});
			}

			setTimeout(() => {
				scrollLatestChat();
			}, 300);
		},
		appendChatMessage(t: IMessage) {
			state.chatMessages.set((x) => {
				x.push({
					...t,
					text: t.text.replaceAll(/<[^>]+>/g, ""),
				});
				return x;
			});

			setTimeout(() => {
				scrollLatestChat();
			}, 300);
		},
		resetState() {
			state.isMicActive.set(false);
			state.language.set("de");
			state.modelName.set("de-DE-SeraphinaMultilingualNeural");
			state.chatMessages.set([]);
		},
	};
}
