import React from "react";
import { useConfiguration } from "../../hooks";
import { LoadingSpinner } from "./LoadingSpinner";
import { useChatState } from "../state/ChatState";
import { DigitalFriendWS } from "./Websocket/DigitalFriendWS";
import { DigitalFriend } from "./DigitalFriend";
import { DigitalFriendChatWS } from "./Websocket/DigitalFriendChatWS";

/**
 * The ConfigurationWrapper component conditionally renders different components based on the configuration
 * and chat state.
 * @returns The ConfigurationWrapper component returns different components based on certain conditions:
 */
export const ConfigurationWrapper = () => {
	const { configurationLoading } = useConfiguration();
	const chatState = useChatState();

	if (configurationLoading) {
		return (
			<div className="min-w-full min-h-screen flex items-center justify-center">
				<LoadingSpinner />
			</div>
		);
	}

	if (chatState?.baseConfig?.USE_WEBSOCKET == "true") {
		if (chatState?.baseConfig?.IS_VOICE_CHAT == "true")
			return <DigitalFriendWS />;
		return <DigitalFriendChatWS />;
	}

	return <DigitalFriend />;
};
