import { useEffect, useState } from "react"

export const useScript = ({
	src,
	cleanup = false,
	appendTo = "head",
}: {
	src: string
	cleanup?: boolean
	appendTo?: "head" | "body"
}) => {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		if (!src) return

		let script: HTMLScriptElement | null = document.querySelector(
			`script[src="${src}"]`,
		)
		let shouldAppend = false

		if (!script) {
			script = document.createElement("script")
			script.src = src
			script.async = true
			shouldAppend = true
		} else if (script.hasAttribute("data-loaded")) {
			setLoaded(true)
		}

		const onScriptLoad = () => {
			if (script) script.setAttribute("data-loaded", "true")
			setLoaded(true)
		}

		const onScriptError = () => {
			if (cleanup === true && script) script.remove()
			setLoaded(false)
		}

		script.addEventListener("load", onScriptLoad)
		script.addEventListener("error", onScriptError)

		if (shouldAppend) {
			document[appendTo === "body" ? "body" : "head"].appendChild(script)
		}

		return () => {
			if (cleanup && script) {
				script.removeEventListener("load", onScriptLoad)
				script.removeEventListener("error", onScriptError)
				if (script.src.includes("testAgentScript"))
					window.symantoAgentTester?.stopConversation()
				script.remove()
			}
		}
	}, [src, cleanup, appendTo])

	return loaded
}
