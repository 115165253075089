import { useState, useEffect } from "react"
import {
	changeApiSettings,
	getConfiguration,
} from "../components/services/chat.service"
import { useChatState } from "../components/state/ChatState"

export const useConfiguration = () => {
	const chatState = useChatState() // global state
	const [configurationLoading, setConfigurationLoading] = useState(true)

	useEffect(() => {
		getConfiguration()
			.then((result) => {
				changeApiSettings(result.BASE_API_URL, result.API_KEY)
				chatState.setBaseConfig(result)
			})
			.catch(console.error)
			.finally(() => setConfigurationLoading(false))
	}, [])

	return { configurationLoading }
}
