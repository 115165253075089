import React from "react"
import { IMessage, useChatState } from "../state/ChatState"
import Markdown from "markdown-to-jsx"
import { useWindowDimensions } from "../../hooks"

export const Message = ({
	message,
	isIframe,
}: {
	message: IMessage
	isIframe: boolean
}) => {
	const chatState = useChatState() // global state

	const useWhiteText =
		chatState.baseConfig?.USE_WHITE_TEXT_MESSAGE_BUBBLES === "true"
	const aiCharacterBackground = chatState.baseConfig?.AI_BUBBLE_TEXT ?? null

	const productChatLogoUrl =
		chatState.baseConfig?.PRODUCT_CHAT_LOGO_URL ||
		"https://symantopublic.blob.core.windows.net/logos/symanto.svg"

	const isVoiceChat = chatState.baseConfig?.IS_VOICE_CHAT == "true"

	const { width: screenWidth } = useWindowDimensions()

	const logoSize = isIframe
		? "w-[30px] h-[30px]"
		: "w-[40px] h-[40px] md:w-[60px] md:h-[60px]"
	const fontSize = isIframe ? "text-xs md:text-sm" : "text-sm md:text-lg"
	const iconSize = isIframe
		? "max-w-[10px] max-h-[10px] md:max-w-[18px] md:max-h-[18px]"
		: "max-w-[14px] max-h-[14px] md:max-w-[26px] md:max-h-[26px]"
	const userIconContainerSize = isIframe
		? "w-[30px] h-[30px] md:w-[40px] md:h-[40px]"
		: "w-[40px] h-[40px] md:w-[60px] md:h-[60px]"

	return (
		<div className="mb-2 md:mb-4">
			{message.messageAuthor === 0 && (
				<div className="flex gap-3 md:gap-4">
					<div className="min-h-full flex items-end">
						{!productChatLogoUrl && !!aiCharacterBackground && (
							<div className="min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px] md:min-h-[60px] md:min-w-[60px] md:max-h-[60px] md:max-w-[60px] bg-secondary rounded-full flex items-center justify-center relative">
								<div
									className={`character-background text-3xl ${
										useWhiteText ? "text-white" : "text-black"
									}`}
								>
									{aiCharacterBackground}
								</div>
							</div>
						)}

						{!!productChatLogoUrl && (
							<img
								src={productChatLogoUrl}
								className={`${logoSize} object-contain mb-2`}
							/>
						)}
					</div>
					<div className="flex-1 flex">
						<div className="flex items-center bg-white text-black text-lg p-3 md:p-6 rounded-lg relative shadow-sm">
							{message.finalMessage === false && (
								<span className="absolute flex h-3 w-3 top-2 right-2">
									<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary invert opacity-75"></span>
									<span className="relative inline-flex rounded-full h-3 w-3 bg-secondary invert"></span>
								</span>
							)}
							<div style={{ lineHeight: isIframe ? 0 : 1.3 }}>
								<Markdown
									className={`no-tailwindcss ${fontSize}`}
									options={{
										overrides: {
											a: {
												component: ({ children, ...props }) => (
													<a
														{...props}
														target="_blank"
														rel="noopener noreferrer"
													>
														{children}
													</a>
												),
											},
										},
									}}
								>
									{message?.text ?? ""}
								</Markdown>
							</div>
							{isVoiceChat && !!message?.time && (
								<p className="ml-4 md:ml-8 text-xs md:text-sm">
									{message.time}
								</p>
							)}
						</div>
					</div>
					{screenWidth >= 768 && <div className="w-[60px]" />}
				</div>
			)}

			{message.messageAuthor === 1 && !!message?.text?.length && (
				<div className="flex justify-end gap-3 md:gap-4">
					{screenWidth >= 768 && <div className="w-[60px]" />}
					<div className="flex-1 flex justify-end">
						<div className="flex items-center text-lg rounded-2xl rounded-br-none relative">
							{message.finalMessage === false && (
								<span className="absolute flex h-3 w-3 top-2 right-2">
									<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary invert opacity-75"></span>
									<span className="relative inline-flex rounded-full h-3 w-3 bg-primary invert"></span>
								</span>
							)}
							{isVoiceChat && !!message?.time && (
								<p className="mr-4 md:mr-8 text-xs md:text-sm">
									{message.time}
								</p>
							)}
							<span className="text-sm md:text-lg">
								{message?.text ??
									"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, vestibulum lectus sit amet, aliquam nunc. Nulla facilisi. Nulla nec purus feugiat, vestibulum lectus sit amet, aliquam nunc. Nulla facilisi."}
							</span>
						</div>
					</div>
					<div className="min-h-full flex items-end">
						<div
							className={`${userIconContainerSize} flex justify-center items-center bg-primary rounded-full pt-1`}
						>
							<img
								src="/ic_person.svg"
								className={`${iconSize} object-contain mb-1 md:mb-2`}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
