import React, { useMemo, useState } from "react";
import { IMessage, useChatState } from "../../state/ChatState";
import { useScript } from "../../../hooks/useScript";
import { applyConfiguration } from "../../helpers/applyConfiguration";
import { ChooseAgent } from "./ChooseAgent";
import { LoadingSpinner } from "../LoadingSpinner";
import { Message } from "../Message";

declare global {
	interface Window {
		symantoAgentTester: {
			startConversation: (
				agentId: string,
				apiKey: string,
				baseWebSocketUrl: string,
				isChat?: boolean,
			) => void;
			stopConversation: () => void;
			test: () => void;
			messageChannel: MessageChannel;
			pauseMic: () => void;
			unpauseMic: () => void;
			sendTextMessage: (message: string) => void;
		};
	}
}

export const DigitalFriendChatWS = () => {
	const chatState = useChatState(); // global state
	const loaded = useScript({ src: "/scripts/testAgentScript.js" });
	const messages = chatState.chatMessages;

	const queryParams = new URLSearchParams(location.search);
	const isIframe = queryParams.get("iframe") === "true";
	const isVoiceChat = chatState.baseConfig?.IS_VOICE_CHAT == "true";
	const chatSuggestions = chatState.baseConfig?.CHAT_SUGGESTIONS?.split("|");
	const keyboardLabels = chatState.baseConfig?.KEYBOARD_LABELS?.split("|");
	const connectingMessages =
		chatState.baseConfig?.CONNECTING_MESSAGES?.split("|");
	const productChatLogoUrl =
		chatState.baseConfig?.PRODUCT_CHAT_LOGO_URL ||
		"https://symantopublic.blob.core.windows.net/logos/symanto.svg";

	const [initApproved, setInitApproved] = useState(false);
	const [isInitializing, setInitializing] = useState(true);
	const [languageIndex, setLanguageIndex] = useState(0);
	const [userMessage, setUserMessage] = useState<string>("");

	const chatSuggestionsByLanguage = useMemo(
		() => chatSuggestions?.[languageIndex]?.split(";"),
		[chatSuggestions, languageIndex],
	);

	applyConfiguration(
		String(chatState.baseConfig?.PRIMARY_COLOR),
		String(chatState.baseConfig?.SECONDARY_COLOR),
		String(chatState.baseConfig?.TITLE),
		chatState.baseConfig?.BACKGROUND_COLOR,
	);

	/**
	 * The `startCall` function initiates a conversation with a Symanto agent, processes incoming
	 * messages, updates the chat state, and sets the microphone as active.
	 * @param {string} agentId - The `agentId` parameter in the `startCall` function is a string that
	 * represents the identifier of the agent with whom the conversation will be started.
	 */
	const startCall = (agentId: string) => {
		chatState.resetState();
		window.symantoAgentTester.startConversation(
			agentId,
			chatState.baseConfig!.API_KEY,
			chatState.baseConfig!.BASE_API_URL,
			true,
		);

		window.symantoAgentTester.messageChannel.port2.onmessage = (event) => {
			const { id, role, isComplete, content } = event.data;
			const _msg: IMessage = {
				id: id,
				conversationId: id,
				messageAuthor: role == "2" ? 0 : 1,
				text: content,
				finalMessage: isComplete,
				date: new Date().toISOString(),
			};
			chatState.updateMessage(_msg, true);
		};
	};

	/**
	 * The `initChat` function initializes a chat with an agent using the provided agentId and optional
	 * promptId.
	 * @param {number} langIndex - The `languageIndex` parameter is a required number that represents the selected language from the choose agent view
	 * @param {string} agentId - The `agentId` parameter is a required string that represents the
	 * identifier of the agent for the chat initialization.
	 * @param {string} [promptId] - The `promptId` parameter in the `initChat` function is an optional
	 * parameter of type string. It is used to specify a prompt ID that can be passed to the `startCall`
	 * function when initiating a chat. If a `promptId` is provided, the `startCall` function
	 */
	const initChat = (langIndex: number, agentId: string, promptId?: string) => {
		setInitializing(false);
		setInitApproved(true);
		setLanguageIndex(langIndex);

		if (promptId && promptId != "undefined") {
			startCall(promptId);
		} else {
			startCall(agentId);
		}
	};

	const sendMessage = (event: React.FormEvent) => {
		event.preventDefault();

		window.symantoAgentTester.sendTextMessage(userMessage);

		setUserMessage("");
	};

	const onSuggestionClick = (suggestion: string) => {
		window.symantoAgentTester.sendTextMessage(`${suggestion}`);
	};

	const selectDefaultAgent = () => {
		const agentId = String(chatState.baseConfig?.AGENT_IDS.split("|")?.[0]);
		const promptId = chatState.baseConfig?.PROMPT_ID;
		initChat(0, agentId, promptId);
	};

	useMemo(() => {
		if (loaded && isIframe && isInitializing) selectDefaultAgent();
	}, [loaded, isIframe]);

	return (
		<div className="min-h-[100vh] max-h-[100vh] flex overflow-y-auto">
			{loaded === false && (
				<div className="flex flex-1 items-center justify-center">
					<LoadingSpinner />
				</div>
			)}

			{loaded === true && (
				<div className="flex flex-1 flex-col items-center p-4 overflow-y-auto">
					{initApproved && (
						<div className="w-full max-w-[900px] flex flex-col flex-1 overflow-y-auto">
							{(isInitializing || messages.length == 0) && (
								<div className="flex w-full mt-1 md:mt-4 gap-3 items-center">
									{!!productChatLogoUrl && (
										<img
											src={productChatLogoUrl}
											className="w-full max-w-[50px] max-h-[50px] md:max-w-[60px] md:max-h-[60px] object-contain"
										/>
									)}
									<p className="text-sm md:text-base">
										{connectingMessages?.[languageIndex]}
									</p>
								</div>
							)}

							{!isInitializing && (
								<>
									<div
										id="chat"
										className="flex flex-1 flex-col gap-2 md:gap-6 overflow-y-auto"
									>
										{messages?.map((message, index) => {
											return (
												<Message
													key={index}
													message={message}
													isIframe={false}
												/>
											);
										})}

										{messages?.length === 1 &&
											!!chatSuggestionsByLanguage?.length && (
												<div className="flex flex-1 items-end justify-center">
													<div className="w-full flex md:px-[60px]">
														<div className="w-full md:px-3 flex flex-col">
															<div className="w-full flex items-center gap-2 md:gap-4">
																{chatSuggestionsByLanguage.map(
																	(suggestion, index) => (
																		<div
																			key={index}
																			className="flex flex-col flex-1 border border-gray-200 rounded-lg h-full p-2 md:p-4 cursor-pointer hover:bg-white hover:shadow-md"
																			onClick={() =>
																				onSuggestionClick(suggestion)
																			}
																		>
																			<img
																				src="core-lightbulb.svg"
																				className="w-[18px] h-[18px] md:w-[24px] md:h-[24px]"
																			/>
																			<p className="mt-2 word-break-all text-sm md:text-base">
																				{suggestion}
																			</p>
																		</div>
																	),
																)}
															</div>
														</div>
													</div>
												</div>
											)}
									</div>
									{!isVoiceChat && (
										<div className="w-full mt-3 md:mt-5 p-0.5 flex">
											<div className="w-full flex md:px-[60px]">
												<div className="w-full md:px-3 flex flex-col">
													<form onSubmit={(event) => sendMessage(event)}>
														<div className="flex flex-1 relative w-full">
															<input
																type="text"
																className="block w-full p-3 md:p-5 text-sm md:text-base text-gray-900 rounded-lg bg-white shadow-sm focus:primary pr-14 md:pr-20"
																placeholder={`${
																	keyboardLabels?.[languageIndex] ?? "Message"
																}`}
																value={userMessage}
																onChange={(e) => setUserMessage(e.target.value)}
																autoComplete="off"
																required
															/>

															<button
																type="submit"
																disabled={!userMessage?.length}
																className="absolute end-1 bottom-[6px] md:end-2 md:bottom-2.5 px-2 py-1 md:px-3 md:py-2 rounded-lg bg-transparent hover:shadow-xl disabled:opacity-20 disabled:pointer-events-none"
															>
																<img src="/ic_send.svg" />
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									)}
								</>
							)}
						</div>
					)}

					{initApproved === false && <ChooseAgent selectedAgent={initChat} />}

					{!isIframe && (
						<div className="flex items-center justify-center mt-3 md:mt-6">
							<img
								src="/powered-by-symanto-logo.svg"
								className="max-h-[26px] md:max-h-[60px]"
								alt="Logo"
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
