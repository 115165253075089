import React from "react";
import { useChatState } from "../../state/ChatState";
import { mapFontSize } from "../../helpers/helper";
import { useWindowDimensions } from "../../../hooks";

export const ChooseAgent = ({
	selectedAgent,
}: {
	selectedAgent: (
		langIndex: number,
		agentId: string,
		promptId?: string | undefined,
	) => void;
}) => {
	const chatState = useChatState();

	const { width: screenWidth } = useWindowDimensions();
	const productLogoUrl =
		chatState.baseConfig?.PRODUCT_LOGO_URL ||
		"https://symantopublic.blob.core.windows.net/logos/symanto.svg";
	const homeTitles = chatState.baseConfig?.HOME_TITLES?.split("|");

	const selectedLanguage = (langIndex: number) => {
		const agentId = String(
			chatState.baseConfig?.AGENT_IDS.split("|")?.[langIndex],
		);
		const promptId = chatState.baseConfig?.PROMPT_ID;
		selectedAgent(langIndex, agentId, promptId);
	};

	return (
		<div className="flex flex-col flex-1 w-full align-middle justify-center backdrop-blur-sm items-center">
			{/* show product url */}
			{!!productLogoUrl && (
				<img
					src={productLogoUrl}
					className="h-full max-h-[40px] md:max-h-[60px] object-contain mb-2"
				/>
			)}

			{/* show home titles */}
			{homeTitles?.map((title, index) => (
				<p
					style={{ fontSize: mapFontSize(index, screenWidth) }}
					key={index}
					className={`text-center mt-2 md:mt-4`}
				>
					{title}.
				</p>
			))}

			<div className="flex flex-row flex-wrap items-center justify-center gap-4 md:gap-8 mt-14">
				{chatState.baseConfig?.BUTTONS.split("|").map((button, index) => (
					<button
						key={index}
						onClick={() => selectedLanguage(index)}
						className="bg-primary hover:bg-opacity-80 text-white font-bold py-2 px-4 md:py-3 md:px-6 rounded-lg md:rounded-xl text-sm md:text-base "
					>
						{button}
					</button>
				))}
			</div>
		</div>
	);
};
